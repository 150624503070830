export function Isotype(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			role="img"
			aria-label="Timple.AI isotype"
			viewBox="0 0 205 238.79"
			x={0}
			xmlSpace="preserve"
			xmlns="http://www.w3.org/2000/svg"
			y={0}
			{...props}
		>
			<path
				d="M188.1 179.39v-8.59h-3.06v-1.36h7.82v1.36h-3.06v8.59h-1.7zM203.38 179.39v-8.62l-3.13 8.62h-1.45l-3.14-8.66v8.66h-1.62v-9.95h2.71l2.77 7.67 2.79-7.67h2.7v9.95h-1.63zM57.5 39c2.86 0 8.37.7 13.08 5.41l74.98 75.01-74.97 74.95c-4.71 4.71-10.22 5.42-13.08 5.42s-8.37-.7-13.08-5.42c-7.15-7.15-7.21-18.73-.2-25.96l21.75-20.88 29.29-28.13-29.3-28.14-21.75-20.88c-7.01-7.23-6.95-18.81.2-25.96C49.13 39.7 54.64 39 57.5 39m0-39C42.78 0 28.07 5.61 16.84 16.84c-22.46 22.46-22.46 58.86 0 81.32l22.11 21.23-22.11 21.23c-22.46 22.46-22.46 58.86 0 81.32 11.23 11.23 25.94 16.84 40.66 16.84s29.43-5.61 40.66-16.84l102.55-102.52L98.16 16.84C86.93 5.61 72.22 0 57.5 0z"
				fill="currentColor"
			/>
		</svg>
	);
}
